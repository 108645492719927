export const agGridGrids = {
    CHEMICAL: 'chemical',
    CHEMICAL_Id: 'F8AD4D7F-8219-4036-AF9B-D500749C6AA6',
    SDS_CENTER: 'sdsCenter',
    SDS_CENTER_Id: '',
    EMPLOYEE: 'employee',
    EMPLOYEE_Id: '7CACE4B4-D79F-4127-8138-AF26FD725881',
    EMPLOYEE_MEDICAL_RECORDKEEPING: 'employeeMedicalRecordKeeping',
    EMPLOYEE_MEDICAL_RECORDKEEPING_Id: 'A037190F-D26B-4740-82C1-8FFAB47BB0E0',
    INCIDENT: 'incident',
    INCIDENT_Id: '52542230-156D-4C98-B204-F1795A3C942F',
    MEDICAL_RECORDKEEPING: 'medicalRecordKeeping',
    MEDICAL_RECORDKEEPING_Id: '16CFF210-8FC6-4D3E-8443-6E3F5F957CFC',
    INCIDENT_TREND: 'incidentTrend',
    INCIDENT_TREND_Id: '495C2C9A-D209-4FF2-A8C7-76EF2B2A20F3',
    TASKS: 'tasks',
    TASKS_Id: '12B128D2-0997-466B-8CD5-A785D19F4E8F',
    SDS_SEARCH_RESULT: 'sdsSearchResult',
    SDS_SEARCH_RESULT_Id: 'D8F27F11-2EA6-45A2-886A-019D4CC08A21',
    EMPLOYMENT_DATA: 'employmentData',
    EMPLOYMENT_DATA_Id: '904BB56C-F3B7-4E0B-8FCD-465C31209205',
    EMPLOYEE_TRAINING: 'employeetraining',
    EMPLOYEE_TRAINING_Id: '',
    LOCATION: 'location',
    LOCATION_Id: '673a541d-e961-49b3-8436-3e6634a2330f',
    TRAINING_ASSET: 'trainingAsset',
    TRAINING_ASSET_Id: '31ba1d1b-2e4b-4708-a400-1aac18e4e2da',
    CLASSROOM_TRAINING_EVENT: 'classroomTrainingEvent',
    CLASSROOM_TRAINING_EVENT_Id: '6FD3821D-87DF-4EE9-8E6E-AFBE3CF0FAD6',
    SELFASSIGNMENTCHECKLIST: 'selfAssignmentChecklist',
    AUDIT_EVENT: 'auditEvent',
    AUDIT_EVENT_ID: 'DF020F05-BBFD-48F0-AC38-68A514554F5B',
    SDS_MANAGEMENT_REQUESTS: 'sds_management_requests',
    SDS_MANAGEMENT_REQUESTS_Id: '3D0A2746-0C05-47B0-BDD1-5CB2F31A2F6D',
    SDS_MANAGEMENT_GLOBAL_PRODUCTS: 'sds_management_global_products',
    SDS_MANAGEMENT_GLOBAL_PRODUCTS_Id: 'EE28DD0B-F967-4B29-8478-8661EE668126',
    SDS_MANAGEMENT_GLOBAL_PRODUCT_SDS: 'sds_management_global_product_sds',
    ENROLLMENT_ORDERS: 'enrollmentOrders',
    FORM_300301_CSV_LOCATIONS: 'form_300301_csv_locations',
    ONLINE_COURSE: 'onlineCourse',
    ONLINE_COURSE_Id: 'BD71D984-81D4-4A5D-9301-0A4A770118E8',
    LEARNER_GROUP: 'learnerGroup'
};
